<template>
    <div class="useradd">
        <header style="height: 120px; text-align: center; line-height: 120px">
            <div style="color: #4d4d4d; font-size: 24px">创建用户</div>
        </header>
        <main>
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                size="small"
                label-width="100px"
                style="width: 400px; margin: 0 auto"
            >
                <el-form-item label="企业名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="登陆账号" prop="username">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="登陆密码" prop="password">
                    <el-input v-model="form.password"></el-input>
                </el-form-item>
                <!-- <el-form-item label="企业激活码">
                    <el-input  v-model="form.co_code"></el-input>
                </el-form-item> -->
                <el-form-item label="绑定手机号" prop="mobile">
                    <el-input v-model="form.mobile"></el-input>
                </el-form-item>
                <el-form-item label="账号类型" prop="type">
                    <el-select v-model="form.account_type" placeholder="请选择账号类型">
                        <el-option label="企业" value="1"></el-option>
                        <el-option label="个人" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="企业服务类型">
                    <el-select v-model="form.package_type" placeholder="请选择活动区域">
                        <el-option v-for="(item,index) in package_info" :key="index" :label="item.p_name" :value="item.p_id"></el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="品牌">
                    <el-select v-model="form.region" placeholder="请选择活动区域">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="法人">
                    <el-input v-model="form.e_legal_person"></el-input>
                </el-form-item>
                <el-form-item label="企业对接员">
                    <el-input v-model="form.e_dj_people"></el-input>
                </el-form-item>
                <el-form-item label="对接人电话">
                    <el-input v-model="form.e_dj_people_phone"></el-input>
                </el-form-item>
                <el-form-item label="企业地址">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>
                <el-form-item label="备注说明">
                    <el-input type="textarea" v-model="form.e_remark"></el-input>
                </el-form-item>
                <el-form-item>
                    <div style="text-align: center">
                        <el-button type="primary" @click="onSubmit">保存</el-button>
                        <router-link to="/user" custom v-slot="{ navigate }">
                            <el-button @click="navigate" @keypress.enter="navigate" role="link">取消</el-button>
                        </router-link>
                    </div>
                </el-form-item>
            </el-form>
        </main>
    </div>
</template>
<script>
import { add, package_info } from '../../common/js/setting';
import $ from 'jquery';
export default {
    name: 'useradd',
    data() {
        return {
            form: {
                name: '',
                username: '',
                password: '',
                // co_code: '',
                mobile: '',
                account_type: '',
                // package_type: '',
                e_legal_person: '',
                e_dj_people: '',
                e_dj_people_phone: '',
                address: '',
                e_remark: ''
            },
            package_info: [''],
            rules: {
                name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                username: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    {
                        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                        message: '请输入正确的手机号',
                        trigger: 'change'
                    }
                ],
                type: [{ required: true, message: '请选择账号类型', trigger: 'blur' }]
            }
        };
    },
    mounted() {
        package_info().then(res => {
            if (res.code == 200) {
                this.package_info = res.result;
                // console.log(res.result);
            }
        });
    },
    methods: {
        onSubmit() {
            var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则
            if (this.form.name == '') {
                this.$message.error('请填写企业名称');
            } else if (this.form.username == '') {
                this.$message.error('请填写登录账号');
            } else if (this.form.password == '') {
                this.$message.error('请填写登录密码');
            } else if (this.form.mobile == '') {
                this.$message.error('请填写绑定手机号');
            } else if (this.form.account_type == '') {
                this.$message.error('请选择账号类型');
            } else {
                if (!reg_tel.test(this.form.mobile)) {
                    this.$message.error('请填写正确的手机号');
                } else {
                    add(this.form).then(res => {
                        if (res.code == 200) {
                            let form = {
                                name: '',
                                username: '',
                                password: '',
                                // co_code: '',
                                mobile: '',
                                account_type: '',
                                // package_type: '',
                                e_legal_person: '',
                                e_dj_people: '',
                                e_dj_people_phone: '',
                                address: '',
                                e_remark: ''
                            };
                            this.$message.success('新建用户成功');
                            this.$router.push('/user');
                            this.form = form;
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            }
        }
    }
};
</script>
<style></style>
